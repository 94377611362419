<template>
  <div class="privacy" :style="{'minHeight':cHeight}">
    <div class="banner">
      <img src="../assets/img_policy.png" alt="">
    </div>
    <div class="main">
      <div class="main_in">
        <div class="m30" style="text-align:center">《律兜法律咨询隐私政策协议》</div>
        <div>更新日期：2022年4月15日</div>
        <div>生效日期：2022年4月08日</div>
        <div class="m60">欢迎您签署《隐私政策》（下称“本协议”）并使用律兜产品/服务！</div>
        <div class="m60">
          无锡中铠信息咨询服务有限公司（以下统称“我们”或“中铠信息”），作为一家专注互联网法律服务的公司，其下属律兜法律咨询服务平台为专业向企业提供各项法律服务的工具，我们一直以来都非常重视法律服务中个人信息保护和隐私权保护。本协议是为了切实保护您的个人信息，优化用户体验而与您签订。本协议旨在说明在您使用律兜产品/服务时，我们如何收集、使用、保存、共享和转让您的个人信息信息，以及您对您的个人信息享有的相关权利。本协议是《用户服务协议》的组成部分，与前述协议具有同等法律效力。
        </div>
        <div>目 录</div>
        <div>一、特别提示</div>
        <div>二、我们如何收集和使用您的个人信息</div>
        <div>三、我们如何共享、转让、公开披露您的个人信息</div>
        <div>四、我们如何保护和储存您的个人信息</div>
        <div>五、您如何管理个人信息</div>
        <div>六、我们如何使用 Cookies 和同类技术</div>
        <div>七、我们如何保护未成年人的个人信息</div>
        <div>八、通知和修订</div>
        <div>九、如何联系我们</div>
        <div>十、争议解决</div>
        <div class="m60">一、特别提示</div>
        <div>
          1.本协议适用各类律兜平台以及律兜平台提供的各项律兜服务，包括律兜网站（www.ilvdo.com）、律兜客户端应用程序（如律兜法律咨询App)等网站及软件，以及该网站及软件上为您提供的各种律兜服务。本协议由您和无锡中铠信息咨询服务有限公司共同签署。
        </div>
        <div>
          2.本协议涉及的术语，我们尽量以简单易懂的方式向您表述。在签署本协议前，请您务必审慎阅读、充分理解本协议各条款的内容，尤其是免除或限制责任/权利的条款、法律适用与争议解决条款、或者其他以下划线/粗体/倾斜/高亮等形式特别标识的条款。如您对本协议内容有任何疑问的，您可拨打律兜客服电话与我们联系，以便我们为您做出解答。如果您对协议条款内容存有异议的，您可以选择不接受或者不使用本服务；若您选择接受、使用此服务的，将视为您接受、认可并同意按照本协议约定执行。
        </div>
        <div>
          3.您同意，一旦您勾选本协议的选框，或者点击同意本协议的按扭，或者有实际登录或使用律兜产品/服务等行为的，即表示您已充分阅读、理解、认可和接受本协议的全部内容，自愿接受本协议约束，并与律兜平台达成一致，成为律兜用户。阅读本协议过程中，如您不同意本协议或其中任何条款约定，您应立即停止注册、登录或使用律兜产品/服务。
        </div>
        <div>4.如您是未成年人的，建议您请您的父母或监护人仔细阅读本信息保护协议；如果你是14周岁以下儿童的，请您在征得您的父母或监护人对本信息保护协议同意的前提下使用我们的服务或向我们提供信息。</div>
        <div>如您未满14周岁且未获得父母或者其他监护人的许可，您应立即停止注册、登录或使用律兜产品/服务，并注销其他已注册的律兜平台账户。</div>
        <div>5.本协议的各项标题表述仅为概括条款使用，不作为对于具体条款解释的限制、参考。对于条款的解释，应以协议目的为出发点并结合上下文义做出合理解释。</div>
        <div class="m60">二、我们如何收集和使用您的个人信息</div>
        <div>
          我们会遵循法律规定的正当、合法、必要等原则，出于本政策所述的以下目的，收集和使用您在使用律兜服务过程中产生的以及您主动提供给我们的个人信息。如果我们将您的个人信息用于本政策未载明的其它用途，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
        </div>
        <div>
          个人信息：个人信息是指以电子或其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，不包括匿名化处理后的信息。个人信息包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。
        </div>
        <div>
          个人敏感信息：个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身或财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，以及不满十四周岁未成年人的个人信息。例如：身份证件号码、个人生物识别信息、银行账号、通信内容、健康生理信息等。个人敏感信息将以右上角标注星号（*）的方式向您显著提示。
        </div>
        <div class="m60">（一）您须授权我们收集和使用您个人信息的情形</div>
        <div>
          律兜提供一些服务和业务功能必须依赖部分信息才得到运行。您选择使用该项服务或业务功能，则需要向我们提供或允许我们收集相关的必要信息。如果您不提供相关信息，我们将无法为您提供该项服务或业务功能。相关业务功能及收集的信息包括：
        </div>
        <div>1.帮助您成为我们的在线客户</div>
        <div>（1）用户注册</div>
        <div>您需要注册一个律兜账户成为律兜用户。当您注册时，您需要向我们提供以下信息：你准备使用的平台账户名、密码、您本人的手机号码*（用于实名认证），我们将通过发送短信验证码验证您的身份是否有效。您的账户名为您的默认昵称。
        </div>
        <div>
          注册律兜用户并同意《用户服务协议》后，您可使用律兜的核心业务功能，包括：在线发布咨询（包含文字咨询、电话咨询、视频咨询）、在线咨询律师（包含文字咨询、电话咨询、视频咨询）、在线购买律兜产品/服务（包含私人律师服务、文书服务、约见律师等）。
        </div>
        <div>当您成功注册律兜账号后可以使用律兜账号登录律兜平台，登录时您需要向我们提供您的律兜账号信息。若您不提供这类信息，您将无法登录和使用律兜的核心业务。</div>
        <div>2.向您提供律兜服务</div>
        <div>（1）浏览、查找与收藏</div>
        <div>
          您可在律兜平台上浏览律兜服务信息，通过问题类型、擅长领域等关键词搜索、查找律师信息，或者在线发布咨询信息。为了方便你快速找到相关服务，我们可能会收集您使用律兜产品/服务的设备信息（包括设备名称、设备型号、设备序列号、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID等软硬件信息）、浏览器类型，来为您提供律兜服务信息展示的最优方式。
        </div>
        <div>（2）在线发布咨询/咨询律师</div>
        <div>
          您可通过图文或电话方式在律兜平台发布或向律兜平台的入驻律师提出法律咨询请求。在此过程中，我们会收集您填写的案件信息、上传的图文信息、咨询的案件类型、您的手机号码*。您填写的案件信息、上传的图文信息可能包含您的其他个人信息（如您的姓名、性别、出生日期、身份证号码*、住址、财产信息*等），除非您的服务必须提供和使用这些信息，否则您可在使用匿名、去标识、内容替换等方式处理相关信息后再将其提供给律兜，以便您不用向律兜提供额外的个人信息。
        </div>
        <div>（3）购买及支付</div>
        <div>
          您可在律兜平台上付费提出法律咨询请求或购买律兜提供的其他付费服务，并可选择第三方支付机构（包含支付宝支付和微信支付）提供的支付服务完成支付。在此过程中，我们可能会收集您的第三支付账号信息*（如支付宝账号、微信账号），同时我们需要将您的订单信息*（如订单号、订单金额）与相关第三方支付机构共享以实现确认您的支付指令并完成支付。若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票电子邮箱。
        </div>
        <div>（4）服务与交付产品</div>
        <div>
          在为您提供律兜服务的过程中，为提高服务质量，相关服务人员（包括律兜平台入驻律师或律兜自建法律服务团队人员）可能根据实际情况收集您的具体案情信息。为向您交付相应产品/服务成果，服务人员可能根据实际情况收集您的身份信息和联系信息，包括您的姓名、电子邮箱、电话号码、社交账号（如微信账号）、联系地址。上述案情信息可能包含您的其他个人信息（如您的姓名、性别、出生日期、身份证号码*、住址、财产信息*等），除非您的服务必须提供和使用这些信息，否则您可在使用匿名、去标识、内容替换等方式处理相关信息后再将其提供给律兜，以便您不用向律兜提供额外的个人信息。
        </div>
        <div>（5）客服与售后功能</div>
        <div>
          您可向律兜提供的客服和售后联系方式反映您在使用律兜服务中遇到的疑问、以及您对律兜的意见或建议。在此过程中，我们会要求您提供您的账号信息和订单信息*。同时，为保证您的账号安全，我们可能还会核查您的身份信息，要求您提供您的姓名、身份证件号码*信息，以便我们查询和处理您的订单。
        </div>
        <div>3.保障交易安全</div>
        <div>为提高您使用律兜服务时相关系统/软硬件的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过您的网站浏览信息、订单信息、您常用的软件信息、设备信息等手段判断您的账号风险，并可能记录我们认为有风险的链接。
        </div>
        <div class="m60">（二）您可自主选择提供的个人信息的情形</div>
        <div>为了向您提供更优质服务，我们可能需要收集以下相关信息。如果您拒绝，不影响您正常使用律兜的核心业务功能，但我们将无法向您提供特定功能和服务。</div>
        <div>1.基于位置信息的展示和推荐功能。为向您展示或推荐您所在区域可以选择的律兜服务及服务人员，我们可能会收集您的位置信息（GPS和网络位置信息）。比如向您展示和推荐您所在区域的平台入驻律师。</div>
        <div>2.基于图片上传的扩展功能。当您发起图文咨询或需要在服务时上传图片的，我们会访问您的设备及文件信息，收集您上传的图片及文件信息。</div>
        <div>3.基于语音技术的扩展功能。当您发起语音咨询或需要在服务时使用语音的，我们会访问或收集您的设备的录音信息。</div>
        <div>4.基于常驻通知的功能。为让您及时了解律兜产品/服务，以及服务过程的相关信息，我们会向您发送常驻通知。</div>
        <div>
          上述扩展功能可能需要您在您的设备中向我们开启您的定位（位置信息）、相册（图片库）、录音（麦克风）、常驻通知的访问权限，以实现这些功能所涉及的信息的收集和使用。您可以在您的【设备-应用管理-权限管理】中逐项查看您的上述权限的开启状态，并可决定将这些权限随时的开启或关闭。请您注意，您开启这些权限即代表我们可以收集和使用相关个人信息实现上述功能，您关闭这些权限即代表您取消了这些授权，则我们将不再收集和使用您的相关信息，也无法为您提供与此对应的业务功能。
        </div>
        <div class="m60">（三）征得您授权同意的例外</div>
        <div>您理解并同意，在以下情形中，我们可以不征得您的授权同意而收集、使用一些必要的个人信息：</div>
        <div>1.与我们履行法律法规规定的义务相关的；</div>
        <div>2.与国家安全、国防安全直接相关的；</div>
        <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
        <div>4.与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
        <div>5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
        <div>6.所收集的个人信息是您自行向社会公开的；</div>
        <div>7.根据您的要求签订和履行合同所必须的；</div>
        <div>8.从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</div>
        <div>9.维护律兜产品和/或服务的安全稳定运行所必须的，例如发现、处置产品或服务的故障；</div>
        <div>10.用于开展合法新闻报道所必需的，或者出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</div>
        <div>11.法律法规规定的其他情形。</div>
        <div class="m60">（四）我们从第三方获得您个人信息的情形</div>
        <div>
          如果您通过第三方访问律兜平台或购买律兜服务，我们可能从第三方获取您授权共享的账户信息（头像、昵称）、联系信息（姓名、电话）、交易信息*，并在您同意本政策后将您的第三方账户与您的律兜账户绑定。我们会依据与第三方的约定，在符合法律法规的前提下，使用您的个人信息。例如，您通过律兜关联公司运营的律兜法律咨询小程序购买律兜服务，我们可能会获得您在律兜法律咨询小程序的账户信息、联系信息以及与您所购买律兜服务相关的交易信息，以便我们能够为您提供服务。
        </div>
        <div class="m60">（五）使用您个人信息的规则</div>
        <div>1.我们会根据本政策的约定并为实现律兜服务和业务功能对所收集的个人信息进行使用。</div>
        <div>
          2.在收集您的个人信息后，我们将根据实际情况通过技术手段对数据进行去标识化处理。去标识化处理的信息在不借助额外信息的情况下将无法识别个人信息主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息，并在不透露您个人信息的前提下，我们权对用户数据库进行分析并进行商业化利用。
        </div>
        <div>3.请您注意，您在使用律兜服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的服务期间持续授权我们使用。</div>
        <div>4.我们会对律兜服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示律兜服务的整体使用趋势，但这些统计信息中不会包含您的任何身份识别信息。</div>
        <div>5.当我们展示展示您的个人信息时，我们会采用包括内容替换、匿名处理、去标识处理等方式对您的信息进行脱敏，以保护您的信息安全。</div>
        <div>6.当我们要将您的个人信息用于本政策未载明的其它用途时，基于特定目的收集而来的信息用于其他目的时，我们会再次取得您的授权同意。</div>
        <div class="m60">三、我们如何共享、转让和公开披露您的个人信息</div>
        <div class="m60">（一）共享</div>
        <div>1.我们不会与律兜平台服务者以外的任何公司、组织和个人共享您的个人信息，但下列情况除外：</div>
        <div>（1）事先获得您明确的同意或授权；</div>
        <div>（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求必须提供；</div>
        <div>（3）在法律法规允许的范围内，为了保护律兜、律兜关联公司或合作伙伴、您或其他律兜用户、或社会公众利益、财产或安全免遭损害而有必要提供；</div>
        <div>（4）只有共享您的个人信息，才能实现律兜服务的核心业务功能或提供您需要的服务；</div>
        <div>（5）应您需求为您处理您与他人的纠纷或争议；</div>
        <div>（6）符合与您签署的相关协议（包括在线签署的电子协议以及相关平台规则）或其他法律文件约定；</div>
        <div>（7）基于符合法律法规的社会公共利益而使用；</div>
        <div>（8）法律法规规定的其他情形。</div>
        <div>2.我们可能会将您的个人信息与我们的关联公司共享。但我们只会共享必要的个人信息，且受本政策所声明的目的约束。我们的关联方如果要改变个人信息处理目的，将再次征求您的授权或同意。</div>
        <div>上述关联公司，是指律兜现在或将来控制、受控制或与其处于共同控制下的任何公司、机构。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被依法认定的方式。</div>
        <div>
          3.为实现本隐私权政策中声明的目的，我们的某些服务将由我们和合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。目前，我们的授权合作伙伴包括以下类型：
        </div>
        <div>
          （1）分析服务类的授权合作伙伴。除非得到您的明确同意，否则我们不会将您的个人信息与分析服务的合作伙伴分享。但我们可能将您的个人信息匿名化处理后，在保证不会泄露您的个人信息的情况下，将有关信息（比如统计信息、用户间接画像）提供上述合作伙伴使用，以便改善我们的产品或服务，使之更加符合您的需求；
        </div>
        <div>
          （2）供应商、服务提供商或其他合作伙伴。我们会与我们的供应商、服务提供商或其他合作伙伴共享您的部分个人信息，但这些信息仅用于如下用途：协助我们向您提供服务（例如：将您的信息提供给合作律师事务所或律师，以便于合作律师事务所或律师开展服务）；协助我们履行《用户服务协议》所载明的目的；履行我们的责任或实现我们的权利，或者改善律兜产品/服务；保障您的信息安全；（例如：将您的电话号码共享给合作的虚拟号码电信运营商，使得您电话号码以虚拟号码方式提供给您的服务律师，以保障您的信息安全）；分析我们服务的使用方式、衡量服务的有效性、支付便利或进行学术研究和调查。
        </div>
        <div class="m60">（二）转让</div>
        <div>1.除非获得您的明确同意，我们不会将您的个人信息转让给任何公司、组织或个人。</div>
        <div>2.如果律兜发生合并、收购、资产转让、破产清算或类似的交易时，如涉及到个人信息转让，我们会要求新的持有个人信息的公司、组织继续受本政策的约束。否则，我们将要求该公司、组织重新获得您的授权或同意。</div>
        <div class="m60">（三）公开披露</div>
        <div>1.除非获得您的明确同意，我们不会公开披露您的个人信息。</div>
        <div>
          2.基于法律、法律程序、诉讼或政府主管部门强制要求的情况下，我们可能会向有权机关披露您的个人信息。但我们保证，在上述情况发生时，我们要求披露的请求方出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。
        </div>
        <div>（四）共享、转让、公开披露个人信息授权同意的例外</div>
        <div>1.您理解并同意，在以下情形中，我们可以在不征得您的授权或同意的情况共享、转让、公开披露您的个人信息：</div>
        <div>（1）与我们履行法律法规规定的义务相关的；</div>
        <div>（2）与国家安全、国防安全直接相关的；</div>
        <div>（3）与公共安全、公共卫生、重大公共利益直接相关的；</div>
        <div>（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
        <div>（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
        <div>（6）您自向社会公开的个人信息；</div>
        <div>（7）从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</div>
        <div>（8）根据您的要求签订和履行合同所必须的；</div>
        <div>（9）用于维护律兜产品和/或服务的安全稳定运行所必须的，例如发现、处置产品或服务的故障；</div>
        <div>（10）用于开展合法新闻报道所必需的，或者出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</div>
        <div>（11）法律法规规定的其他情形。</div>
        <div>2.根据法律规定，共享、转让去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让或公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </div>
        <div class="m60">四、我们如何保护和储存您的个人信息</div>
        <div class="m60">（一）我们如何保护您的个人信息</div>
        <div>我们非常重视您的个人信息安全。我们将遵守相关法律法规的规定，尽量采取合理可行的措施，保护您的个人信息。</div>
        <div>1.数据安全措施</div>
        <div>我们将努力采用符合业界标准的安全防护措施，积极建立合理的制度规范、采用可行的安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。</div>
        <div>
          我们会视实际情况采取适当的加密技术（如采取https方式提供浏览服务，确保用户信息传输安全，或采取SSL加密保护用户信息），确保用户信息保密性、安全性和可用性。另外，我们还将部署访问控制机制，制定数据分类分级制度和安全管理规范，确保只授权人员才可以访问个人信息。并且，我们将视实际情况，采用与信息接触人员签署保密协议、监控和审计等一种或多种方式对用户信息进行全面安全控制。同时，为了加强安全意识，我们还会举办安全和隐私保护培训活动，加强员工对保护个人信息重要性的认识。
        </div>
        <div>2.安全事件处置</div>
        <div>
          我们将尽力确保您的个人信息的安全性，但请您理解，由于技术的限制以及在互联网行业可能存在的各种恶意手段，不可能始终保证信息百分之百安全。你需要了解，您接入我们服务所用的系统和通讯网络，有可能因我们控制范围外的因素出现问题。为了防止安全事故发生，我们提示您妥善的保护好您自己的个人信息，仅在必要时向他人提供，我们也将制定妥善的安全预警机制和应急预案。
        </div>
        <div>
          若不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以电话、推送通知等方式告知您，难以逐一告知用户的，我们将采取合理、有效的方式发布公告或警示。
        </div>
        <div>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府部门工作。</div>
        <div class="m60">（二）我们如何储存您的个人信息</div>
        <div>1.您的个人信息将存储于中华人民共和国国境内。除非另行征得您的授权同意，且符合法律法规的规定，否则我们不会向境外传输您的个人信息。</div>
        <div>2.我们承诺按法律规定或在合理必要期限内存储您的个人信息。对于超出期限的个人信息，我们会立即删除或做匿名化处理。</div>
        <div>3.如果我们终止服务或运营的，我们会立即停止对您的个人信息的收集，并在终止服务和运营后对您的个人信息进行删除或匿名化处理。</div>
        <div class="m60">五、您如何管理您的个人信息</div>
        <div>我们非常重视您对个人信息的关注，并尽全力保护您对于个人信息的访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</div>
        <div class="m60">（一）访问和更正您的个人信息</div>
        <div>1.除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</div>
        <div>（1）您的账户信息：您可在【我的-个人资料】页面查看您的账号信息，即作为您的账号和实名认证的手机号码信息。</div>
        <div>
          （2）您的订单信息：您可在【我的-我的订单】页面查看您所有的订单信息。如果您使用的是电脑端，您可在网站右上角点击【我的订单】查看上述信息。为了避免我们无法准确判断您的购买信息而难以提供相应的售后服务，也为了避免将来发争议无据可依，我们暂不向您提供订单信息删除功能。
        </div>
        <div>
          （3）对于您在使用律兜服务过程产生其他个人信息需要访问和更正的，您可随时通过本政策公布的联系方式与我们联系。我们会根据相关法律法规规定、本政策列明的方式和期限响应您的请求。一般情况下，对于可以访问或更正的信息，以验证您的身份后，我们将会在30天内或法律法规规定的期限内作为答复及合理解释。
        </div>
        <div>为提升您的用户体验和保证交易安全，您的部分个人信息我们还无法为您提供访问和更正服务，如您用于登录的手机号码，您的设备信息等。</div>
        <div class="m60">（二）删除您的个人信息</div>
        <div>1.在以下情形中，您可以向我们提出删除个人信息的请求：</div>
        <div>（1）如果我们处理个人信息的行为违反法律法规；</div>
        <div>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</div>
        <div>（3）如果我们处理个人信息的行为违反了与您的约定；</div>
        <div>（4）如果您注销了律兜账号；</div>
        <div>（5）如果我们终止服务或运营。</div>
        <div>2.当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份时删除这些信息或作匿名化处理。</div>
        <div class="m60">（三）改变您授权同意的范围</div>
        <div>您可以通过删除信息、关闭设备、关闭系统权限等方式改变您授权我们继续收集个人信息的范围或撤回您的授权或同意。您也可以通过注销账户的方式，撤回我们收集您个人信息的全部授权。</div>
        <div>
          请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务或业务功能，也不能再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </div>
        <div>
          我们在提供服务的过程中，可能需要您开通一些设备权限，例如常驻通知、相册、相机、手机通讯录等访问权限。您可在设备中的【设置-应用管理-应用权限】中随时选择关闭部分或全部权限，从而拒绝我们收集您相应的个人信息。在不同的设备中，权限显示方式及关闭方式可能有所不同，具体请参照您的手机/硬件/系统开发说明或指引。
        </div>
        <div class="m60">（四）注销您的账号</div>
        <div>如果您需要注销您的律兜账号，您可通过本政策公布的联系方式与我们联系，我们将在验证您的身份后，为您提供账号注销服务。</div>
        <div>为了向您提供更加便捷的注销方式，我们后续会不断优化我们的产品，并通过页面向您告知。在您注销账号后，我们将停止为您提供产品或服务，并根据法律法规的要求，尽快删除您的个人信息或作匿名化处理。</div>
        <div class="m60">（五）响应您的请求</div>
        <div>如果您无法通过上述方式访问、更正或删除您的个人信息，或者您需要访问、更正或删除您在使用律兜服务时所产生的其他个人信息，或者您认为律兜存在任何违反法律法规或与违反约定的行为的，您可通过本政策公布的联系方式与我们联系。
        </div>
        <div>
          为保障安全，我们可能需要您提供书面请求，并提供您的身份证明文件，我们将在收到您的反馈并验证您的身份证后30日内或法律法规规定的期限内答复您的请求。对于合理的请求，我们原则上不收取费用，但对于多次重复、超出合理限度的请求，我们将视实际情况收取一定的成本费用。对于那些无端重复、需要通过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
        </div>
        <div>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
        <div>1.与我们履行法律法规规定的义务相关的；</div>
        <div>2.与国家安全、国防安全直接相关的；</div>
        <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
        <div>4.与犯罪侦查、起诉、审判和执行判决等直接相关的；</div>
        <div>5.有充分证据表明您存在主观恶意或滥用权利的；</div>
        <div>6.出于维护您或其他个人生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
        <div>7.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</div>
        <div>8.涉及商业秘密的；</div>
        <div>9.法律法规规定的其他情形。</div>
        <div class="m60">六、我们如何使用Cookie和同类技术</div>
        <div>
          1.为了使您获得更轻松的访问体验，我们会在您的计算机或移动设备上发送一个或多个名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。我们使用该等信息判断注册用户是否已经登录，存储您的用户名和密码（如有），储存您使用律兜服务的记录和偏好，判断您的账户或信息是否安全。
        </div>
        <div>
          2.我们不会将Cookie用于本政策所述目的之外的其它用途。您可清除计算机上保存的所有Cookie，大部分网络浏览器会直接接受Cookie，但您可根据自身的需要修改浏览器的设置拒绝Cookie，或清除软件内保存的所有Cookie及其他信息。但如果您这么做，您可能需要在每一次访问网站时填写登录信息，而且您之前记录的相应信息也均会被删除。
        </div>
        <div>
          3.为提升用户体验，提高服务质量，了解用户使用律兜服务的情形等，我们可能会在律兜移动应用程序（App）中嵌入第三方软件开发工具包（SDK），实现数据统计、分析等功能。您在使用律兜服务时，SDK可能会收集和使用您的部分个人信息。目前，我们使用的SDK【友盟社会化分享】、【极光推送】、【阿里支付】、【微信支付】。
        </div>
        <div>
          4.您在使用律兜服务时，SDK可能收集您的浏览器、互联网服务提供商、IP地址、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标准符、广告标识符、网卡（MAC）地址、移动设备识别码（IMEI）、设备型号等信息。为了让您详细了解相关SDK收集、使用您个人信息的情况，我们向您提供相关SDK的隐私政策链接。
        </div>
        <div class="m60">第三方SDK清单</div>
        <div>请注意，第三方SDK可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，具体请以其公示的官方说明为准。</div>
        <div>· 友盟社会化分享</div>
        <div>所属公司：友盟同欣（北京）科技有限公司</div>
        <div>收集数据类型：设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）</div>
        <div>使用目的：消息分享</div>
        <div>隐私政策/官网链接：https://www.umeng.com/page/policy</div>
        <div>· 极光推送</div>
        <div>所属公司：深圳市和讯华谷信息技术有限公司</div>
        <div>收集数据类型：设备平台、设备厂商、设备品牌、设备序列号等设备信息</div>
        <div>使用目的：设备平台、设备厂商、设备品牌、设备序列号等设备信息</div>
        <div>隐私政策/官网链接：https://www.jiguang.cn/</div>
        <div>· 阿里支付</div>
        <div>所属公司：支付宝（杭州）信息技术有限公司</div>
        <div>收集数据类型：系统语言，系统唯一标识符，外部存储状态，网络类型，设备型号，设备制造商，SIM 卡序列号，IMEI，MAC 地址，ANDROID_ID，IP 地址，WiFi 信息，应用安装列表，OpenUDID
        </div>
        <div>使用目的：支付宝支付</div>
        <div>隐私政策/官网链接：https://render.alipay.com/p/c/k2cx0tg8</div>
        <div>· 微信支付</div>
        <div>所属公司：腾讯</div>
        <div>收集数据类型：系统语言，系统唯一标识符，外部存储状态，网络类型，设备型号，设备制造商，SIM 卡序列号，IMEI，MAC 地址，ANDROID_ID，IP 地址，WiFi 信息，应用安装列表，OpenUDID
        </div>
        <div>使用目的：微信支付</div>
        <div>隐私政策/官网链接：https://payapp.weixin.qq.com/summeract/gdpr/home?lang=cn&nobutton=1</div>
        <div>目前，我们使用的SDK包括以下类型：</div>
        <div>1. 用于消息推送功能，包括收集厂商 Push 推送、特定事件提醒等；</div>
        <div>2. 用于支付相关服务，包括订单支付、交易行为核验、收入结算、支付信息汇总统计等；</div>
        <div>3. 用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息等；</div>
        <div>4. 用于第三方授权服务，将有关内容分享至第三方产品等；</div>
        <div>5. 用于支持产品功能模块，包括即时通讯、智能客服、内容存储等；</div>
        <div>6. 用于优化产品性能，包括提高硬件配网能力、减低服务器成本、功能热修复等；</div>
        <div>7. 用于账号安全、产品加固相关服务，包括网络监测、域名解析、防劫持、反垃圾反作弊、加解密服务等；</div>
        <div>8. 用于业务显示位置信息与定位导航等。</div>
        <div>您理解并同意，如果您同意本政策，即意味着您同意律兜服务嵌入的相关SDK的隐私政策，同意第三方按照其公布的隐私政策通过SDK收集、使用及处理您的个人信息。</div>
        <div class="m60">七、我们如何保护未成年人的个人信息</div>
        <div>
          1.我们的产品、网站和服务主要面向成年人。若您是18周岁以下的未成年人，在使用我们的产品或服务前，您应在监护人的陪同下仔细阅读并充分理解本政策的全部内容，并确保在征得您的监护人的同意后使用我们的服务并向我们提供您的信息。我们会根据法律法规的规定保护未成年人的个人信息。
        </div>
        <div>2.我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露相关信息。</div>
        <div>3.如果有事实证明有18周岁以下的未成年人并未取得监护人同意的情况下注册使用我们的产品或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。</div>
        <div>
          4.对于收集的儿童个人信息，我们除遵守本政策关于用户个人信息的约定外，还会严格遵循法律法规的要求进行收集、使用、保存、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或作匿名化处理。
        </div>
        <div>
          5.当您作为监护人为被监护的儿童选择使用律兜服务时，我们可能需要向您收集儿童的个人信息，用于向您履行相关服务之必要。同时，我们将可能以适当的方式要求您向我们证明您的授权或同意。您作为监护人应当正确履行监护职责，保护儿童个人信息安全，并在儿童使用律兜服务时给予正确的引导。
        </div>
        <div>6.儿童及其监护人有权访问和更正儿童个人信息，还可以向我们提供删除个人信息的请求。如您对儿童个人信息相关事宜有任何疑问、意见、建议或投诉、举报，请通过本政策公布的联系方式与我们联系。</div>
        <div class="m60">八、通知和修订</div>
        <div>
          1.为给您提供更好的服务以及随着律兜业务的发展，本政策会随之更新。但未经您明确同意，我们不会消减您依据本个人信息保护政策所应享有的权利。我们会通过网站公告、弹窗通知、更新协议或其他适当的方式向您提醒相关内容的更新，也请您注册、登录、使用律兜产品/服务时随时注意我们的各种通知。
        </div>
        <div>2.对于重大变更，我们还会提供更为显著的通知，说明本政策具体变更内容。重大变更包括但不限于：</div>
        <div>（1）我们的服务模式发生变化。如处理个人信息的目的、处理个人信息的类型、个人信息的使用方式等；</div>
        <div>（2）我们在所有权结构、组织结构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</div>
        <div>（3）个人信息共享、转让或公开披露的主要对象发生变化；</div>
        <div>（4）您参与个人信息处理方面的权利及行使方式发生重大变化；</div>
        <div>（5）我们负责处理个人信息安全的责任部门、联系方式及投诉渠道发生变化；</div>
        <div>（6）个人信息安全影响评估表明存在高风险时；</div>
        <div>3.若您不同意更新后的个人信息保护政策，您有权并应立即停止使用律兜产品/服务。如果您继续使用律兜的产品和/或服务，则视为您接受相应更新内容。</div>
        <div class="m60">九、如何联系我们</div>
        <div>1.若您对本政策有任何疑问，或您对您个人信息相关事宜有任何问题、意见或建议，您可通过以下方式联系我们：</div>
        <div>联系电话：400-859-1580</div>
        <div>联系地址：无锡市滨湖区锦溪路100号科教创业园3号楼1楼</div>
        <div>你还可以通过我们的网站、律兜App公布的其他联系方式联系我们。</div>
        <div>2.一般情况下，我们将在15天内或法律法规规定的期限内答复。</div>
        <div class="m60">十、争议解决</div>
        <div>1.就本协议所发生的争议，我们希望首先通过与您进行友好沟通协商的方式解决；若协商无法解决争议的，双方均可将争议提交至无锡中铠信息咨询服务有限公司所在地人民法院诉讼解决。</div>
        <div>
          2.有关法律文书的送达：对于因本协议争议而引致的任何纠纷，您同意裁判机关按照您在律兜平台注册账号时所提供的信息（包括但不限于邮箱、手机号码、微信、QQ、联系地址等内容）进行法律文书（包括但不限于权利申请类型文件【例如起诉状、反诉状、仲裁申请书、保全申请书等】的正副本、证据、裁判机关及相关评估、鉴定机构出具的文书等）送达，裁判机关可以采取以上一种或多种方式送达，送达时间以最先送达的为准。您确认，上述送达方式适用于整个争议解决阶段；若您所提供的送达信息不确切、或不及时告知变更的，使法律文书无法送达或未及时送达，由此产生的不利后果将由您自行承担。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'privacy',
    data() {
      return {
        cHeight: null
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'

    },
    methods: {

    },
    components: {}
  }

</script>

<style scoped lang="scss">
  .privacy {
    background: #F5F5F8;
    box-sizing: border-box;
  }

  .banner {
    img {
      width: 100%;
    }
  }

  .main {

    .main_in {
      margin: 30px 30px 0;
      font-size: 26px;
      color: #333;

      .m60 {
        margin: 40px 0;
      }

      .m30 {
        padding-bottom: 30px;
      }

      >div {
        padding-bottom: 30px;
      }
    }

  }

</style>
<style lang="scss">


</style>
